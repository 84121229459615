import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Waves_ from "../img/video.webm"
import Waves from "../img/video.mp4"
const IndexPage = props => (
  <Layout>
    <header className="header" id="about">
      <div className="header__logo-box">
        <Img
          fixed={props.data.top_logo.childImageSharp.fixed}
          alt="alt logo"
          className="header__logo"
        />
      </div>
      <div className="header__text-box">
        <h1 className="heading-primary">
          <span className="heading-primary--main">Innoura</span>
          <span className="heading-primary--sub">
            is where digital products is getting crafted
          </span>
        </h1>
        <a href="#section-services" className="btn btn--white btn--animated">
          Discover our services
        </a>
      </div>
    </header>
    <main>
      <section className="section-about">
        <div className="u-center-text u-margin-bottom-big">
          <h2 className="heading-secondary">
            Let’s build something that your users will love.
          </h2>
        </div>
        <div className="row">
          <div className="col-1-of-2">
            <h3 className="heading-tertiary u-margin-bottom-small">
              You're will fall in love with Digital products again
            </h3>
            <p className="paragraph">
              Innoura Technologies is a design and development consultancy that
              brings your digital product ideas to life.
            </p>
            <h3 className="heading-tertiary u-margin-bottom-small">
              A.I, that supports human productivity to next level
            </h3>
            <p className="paragraph">
              Our Data-engine solves current day deep learning data problems and
              supports causal reasoning for all models out of the box.
            </p>
            <a href="#about" className="btn-text">
              Learn more →
            </a>
          </div>
          <div className="col-1-of-2">
            <div className="composition">
              <Img
                fluid={props.data.system.childImageSharp.fluid}
                alt="1"
                className="composition__photo composition__photo--p1"
              />

              {/*
                            <img src="img/nat-1-large.jpg" alt="Photo 1" class="composition__photo composition__photo--p1">
                            <img src="img/nat-2-large.jpg" alt="Photo 2" class="composition__photo composition__photo--p2">
                            <img src="img/nat-3-large.jpg" alt="Photo 3" class="composition__photo composition__photo--p3">
                            */}
            </div>
          </div>
        </div>
      </section>
      <section className="section-features" id="features">
        <div className="row">
          <div className="col-1-of-4">
            <div className="feature-box">
              <i className="feature-box__icon icon-basic-world" />
              <h3 className="heading-tertiary u-margin-bottom-small">
                Secure Development Lifecycle
              </h3>
              <p className="feature-box__text">
                One of the important steps in secure development is integrating
                testing tools and services such as InnouraSecureCode into the
                software development lifecycle. These tools allow developers to
                model an application, scan the code, check the quality and
                ensure that it meets regulations.
              </p>
            </div>
          </div>
          <div className="col-1-of-4">
            <div className="feature-box">
              <i className="feature-box__icon icon-basic-compass" />
              <h3 className="heading-tertiary u-margin-bottom-small">
                Formal Verification of Distributed System
              </h3>
              <p className="feature-box__text">
                Distributed systems are difficult to implement correctly because
                they must handle both concurrency and failures: machines may
                crash at arbitrary points and networks may reorder, drop, or
                duplicate packets. Further, their behavior is often too complex
                to permit exhaustive testing.
              </p>
            </div>
          </div>
          <div className="col-1-of-4">
            <div className="feature-box">
              <i className="feature-box__icon icon-basic-map" />
              <h3 className="heading-tertiary u-margin-bottom-small">
                Design Thinking
              </h3>
              <p className="feature-box__text">
                Design thinking is a non-linear, iterative process which seeks
                to understand users, challenge assumptions, redefine problems
                and create innovative solutions to prototype and test. The
                method consists of 5 phases—Empathize, Define, Ideate, Prototype
                and Test and is most useful when you want to tackle problems
                that are ill-defined or unknown.
              </p>
            </div>
          </div>
          <div className="col-1-of-4">
            <div className="feature-box">
              <i className="feature-box__icon icon-basic-heart" />
              <h3 className="heading-tertiary u-margin-bottom-small">
                Systematic Innovation
              </h3>
              <p className="feature-box__text">
                We practice systematic innovation methodologies like TRIZ and
                ASIT in problem solving. Which ensures our high R &amp; D
                success rate.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section-tours">
        <div className="u-center-text u-margin-bottom-big">
          <h2 className="heading-secondary" id="section-services">
            Our digital services
          </h2>
        </div>
        <div className="row">
          <div className="col-1-of-3">
            <div className="card">
              <div className="card__side card__side--front">
                <div className="card__picture card__picture--1">&nbsp;</div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--1">
                    Website Development
                  </span>
                </h4>
                <div className="card__details">
                  <ul>
                    <li> Ruby on Rails</li>
                    <li>Elixir / Phoenixs</li>
                    <li>Reacts</li>
                    <li>Python / Djangos</li>
                    <li>Code Audits</li>
                  </ul>
                </div>
              </div>
              <div className="card__side card__side--back card__side--back-1">
                <div className="card__cta">
                  {/*<div className="card__price-box">
                    <p className="card__price-only">Starts from</p>
                    <p className="card__price-value">$297</p>
                          </div>*/}
                  <a href="#popup-website" className="btn btn--white">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1-of-3">
            <div className="card">
              <div className="card__side card__side--front">
                <div className="card__picture card__picture--2">&nbsp;</div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--2">
                    Mobile Development
                  </span>
                </h4>
                <div className="card__details">
                  <ul>
                    <li>Mobile Web</li>
                    <li>IOS</li>
                    <li>Android</li>
                    <li>React Native</li>
                  </ul>
                </div>
              </div>
              <div className="card__side card__side--back card__side--back-2">
                <div className="card__cta">
                  {/*<div className="card__price-box">
                    <p className="card__price-only">Starts from</p>
                    <p className="card__price-value">$497</p>
                        </div>*/}
                  <a href="#popup-mobile" className="btn btn--white">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1-of-3">
            <div className="card">
              <div className="card__side card__side--front">
                <div className="card__picture card__picture--3">&nbsp;</div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--3">
                    BlockChain Development
                  </span>
                </h4>
                <div className="card__details">
                  <ul>
                    <li>Stellar Network</li>
                    <li>R3 Corda</li>
                    <li>Hyperledger</li>
                    <li>Etherium</li>
                    <li>Code Audit</li>
                  </ul>
                </div>
              </div>
              <div className="card__side card__side--back card__side--back-3">
                <div className="card__cta">
                  {/*<div className="card__price-box">
                    <p className="card__price-only">Starts from</p>
                    <p className="card__price-value">$897</p>
                      </div>*/}
                  <a href="#popup-blockchain" className="btn btn--white">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="u-center-text u-margin-top-huge">
                    <a href="#" class="btn btn--green">Discover all tours</a>
                </div> */}
      </section>
      <section className="section-stories" id="section-locations">
        <div className="bg-video">
          <video className="bg-video__content" autoPlay muted loop>
            <source src={Waves} type="video/mp4" />
            <source src={Waves_} type="video/webm" />
            Your browser is not supported!
          </video>
        </div>
        <div className="u-center-text u-margin-bottom-big">
          <h2 className="heading-secondary">Our Locations</h2>
        </div>
        <div className="row">
          <div className="story">
            <figure className="story__shape">
              <Img
                fluid={props.data.singaporeFlag.childImageSharp.fluid}
                alt="Singapore"
                className="story__img"
              />

              <figcaption className="story__caption">Singapore</figcaption>
            </figure>
            <div className="story__text">
              <h3 className="heading-tertiary u-margin-bottom-small">
                Singapore
              </h3>
              <p>
                Innoura Technologies Pvt Ltd
                <br />
                9 Temsasek Boulevard, Suntec Tower 2,
                <br />
                #09-01 Singapore 038989, Fax number: +65 6407 1501
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="story">
            <figure className="story__shape">
              <Img
                fluid={props.data.indiaFlag.childImageSharp.fluid}
                alt="India"
                className="story__img"
              />

              <figcaption className="story__caption">Chennai</figcaption>
            </figure>
            <div className="story__text">
              <h3 className="heading-tertiary u-margin-bottom-small">India</h3>
              <p>
                Innoura Technologies Pvt Ltd
                <br />
                Prince Infocity II,
                <br />
                283/4, OMR, Lakshmi Nagar, Perungudi, Chennai, Tamil Nadu 600096
              </p>
            </div>
          </div>
        </div>
        {/*                 <div class="u-center-text u-margin-top-huge">
                    <a href="#" class="btn-text">Read all testimonials &rarr;</a>
                </div> */}
      </section>
      <section className="section-book" id="section-contact-us">
        <div className="row">
          <div className="book">
            <div className="book__form">
              <form
                action="https://formspree.io/xyrvzldm"
                method="POST"
                className="form"
              >
                <div className="u-margin-bottom-medium">
                  <h2 className="heading-secondary">Contact Us</h2>
                  <h2 className="contact-heading">support@innoura.com</h2>
                  <h2 className="contact-heading">+65 6407 1324</h2>
                </div>

                <div className="form__group">
                  <input
                    type="text"
                    className="form__input"
                    placeholder="Full name"
                    id="name"
                    name="name"
                    required
                  />
                  <label htmlFor="name" className="form__label">
                    Full name
                  </label>
                </div>
                <div className="form__group">
                  <input
                    type="email"
                    className="form__input"
                    placeholder="Email address"
                    id="email"
                    name="email"
                    required
                  />
                  <label htmlFor="email" className="form__label">
                    Email address
                  </label>
                </div>
                <div className="form__group">
                  <textarea
                    className="form__input"
                    id="comments"
                    name="comments"
                    rows="13"
                    cols="40"
                    placeholder="Tell Us About Your Project"
                  />
                  <label htmlFor="" className="form__label">
                    Tell Us About Your Project
                  </label>
                </div>
                <div className="form__group u-margin-bottom-medium">
                  <div className="form__radio-group">
                    {/* <input type="radio" class="form__radio-input" id="small" name="size">
                                        <label for="small" class="form__radio-label">
                                            <span class="form__radio-button"></span>
                                            Small tour group
                                        </label> */}
                  </div>
                  <div className="form__radio-group">
                    {/*  <input type="radio" class="form__radio-input" id="large" name="size">
                                        <label for="large" class="form__radio-label">
                                            <span class="form__radio-button"></span>
                                            Large tour group
                                        </label> */}
                  </div>
                </div>
                <div className="form__group">
                  <button className="btn btn--green" type="submit">
                    Submit →
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>

    <div>
      <footer className="footer">
        <div className="footer__logo-box">
          <picture className="footer__logo">
            <source
              srcSet="img/page_main_logo.png 1x, img/page_main_logo.png 2x"
              media="(max-width: 37.5em)"
            />

            <Img
              fixed={props.data.logo.childImageSharp.fixed}
              alt="alt logo"
              className="header__logo"
            />
          </picture>
        </div>
        <div className="row">
          <div className="col-1-of-2">
            <div className="footer__navigation">
              <ul className="footer__list">
                <li className="footer__item">
                  <a href="#about" className="footer__link">
                    Company
                  </a>
                </li>
                <li className="footer__item">
                  <a href="#section-contact-us" className="footer__link">
                    Contact us
                  </a>
                </li>
                <li className="footer__item">
                  <a href="#section-services" className="footer__link">
                    Services
                  </a>
                </li>
                {/* <li class="footer__item"><a href="#" class="footer__link">Privacy policy</a></li>
                        <li class="footer__item"><a href="#" class="footer__link">Terms</a></li> */}
              </ul>
            </div>
          </div>
          <div className="col-1-of-2">
            <p className="footer__copyright">
              Built by{" "}
              <a href="innoura.com" className="footer__link">
                Innoura Technologies
              </a>
              <br />
              Copyright © by Innoura Technologies.
              <br /> Adopted from the original design of author, Jonas
              Schmedtmann!
            </p>
          </div>
        </div>
      </footer>

      <div className="popup" id="popup-blockchain">
        <div className="popup__content">
          <div className="popup__left">
            <Img
              fluid={props.data.natEight.childImageSharp.fluid}
              alt="3"
              className="popup__img"
            />
            <Img
              fluid={props.data.natNine.childImageSharp.fluid}
              alt="3"
              className="popup__img"
            />
          </div>
          <div className="popup__right">
            <a href="#section-services" className="popup__close">
              ×
            </a>
            <h2 className="heading-secondary u-margin-bottom-small">
              Our Blockchain Solutions
            </h2>
            <h3 className="heading-tertiary u-margin-bottom-small">
              Building Fintech applications using Stellar blockchain platform.
            </h3>
            <p className="popup__text">
              Having a team of Stellar Blockchain development experts, we make
              you understand the potential of Stellar for your business
              perspective. API. With Horizon (Stellar API), we can help deliver
              an enterprise-grade product. Our hands-on experience in building
              software including mobile, payment systems and banking tools. Our
              team of developers has expertise in Java &amp; Solidity and has
              developed 100+ Smart Contracts. We build the fidelity user
              interface design, technical architecture, user stories and develop
              sprints and delivery milestones.
            </p>
            <a href="#section-contact-us" className="btn btn--green">
              Get in touch
            </a>
          </div>
        </div>
      </div>
      <div className="popup" id="popup-website">
        <div className="popup__content">
          <div className="popup__left">
            <Img
              fluid={props.data.natEight.childImageSharp.fluid}
              alt="3"
              className="popup__img"
            />
            <Img
              fluid={props.data.natNine.childImageSharp.fluid}
              alt="3"
              className="popup__img"
            />
          </div>
          <div className="popup__right">
            <a href="#section-services" className="popup__close">
              ×
            </a>
            <h2 className="heading-secondary u-margin-bottom-small">
              Our Website Development Solutions
            </h2>
            <h3 className="heading-tertiary u-margin-bottom-small">
              Website Design drives your application’s success
            </h3>
            <p className="popup__text">
              Create compelling experiences, drive user acquisition, engage with
              customers, and drive home your brand with Website Design. From
              strategy to implementation, we collaborate with you to design
              experiences that build successful Website and businesses.
            </p>
            <a href="#section-contact-us" className="btn btn--green">
              Get in touch
            </a>
          </div>
        </div>
      </div>
      <div className="popup" id="popup-mobile">
        <div className="popup__content">
          <div className="popup__left">
            <Img
              fluid={props.data.natEight.childImageSharp.fluid}
              alt="3"
              className="popup__img"
            />
            <Img
              fluid={props.data.natNine.childImageSharp.fluid}
              alt="3"
              className="popup__img"
            />
          </div>
          <div className="popup__right">
            <a href="#section-services" className="popup__close">
              ×
            </a>
            <h2 className="heading-secondary u-margin-bottom-small">
              Our Mobile App Development
            </h2>
            <h3 className="heading-tertiary u-margin-bottom-small">
              From idea to launch, in eight weeks
            </h3>
            <p className="popup__text<Footer ></Footer>">
              You’ve got the vision. Now you need an expert partner focused on
              delivering value to your business and users. We like to say that
              “mobile” refers to the user, not the device. With that in mind,
              we’ll help you with your first critical product decisions like
              technology, branding, design, and experience. And we’ll do it
              fast.
            </p>
            <a href="#section-contact-us" className="btn btn--green">
              Get in touch
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export const fluidWebpImage = graphql`
  fragment fluidWebpImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
export const headerLogo = graphql`
  fragment headerLogo on File {
    childImageSharp {
      fixed(width: 180) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
`
export const footerImage = graphql`
  fragment footerImage on File {
    childImageSharp {
      fixed(width: 160) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    natOne: file(relativePath: { eq: "nat-1.jpg" }) {
      ...fluidImage
    }
    natTwo: file(relativePath: { eq: "nat-2.jpg" }) {
      ...fluidImage
    }
    natThree: file(relativePath: { eq: "nat-3.jpg" }) {
      ...fluidImage
    }
    natEight: file(relativePath: { eq: "nat-8.jpg" }) {
      ...fluidImage
    }
    natNine: file(relativePath: { eq: "nat-3.jpg" }) {
      ...fluidImage
    }
    top_logo: file(relativePath: { eq: "logo-white1.png" }) {
      ...headerLogo
    }
    logo: file(relativePath: { eq: "page_main_logo.png" }) {
      ...footerImage
    }
    singaporeFlag: file(
      relativePath: { eq: "1920px-Flag_of_the_President_of_Singapore.svg.png" }
    ) {
      ...fluidImage
    }
    indiaFlag: file(relativePath: { eq: "510px-Flag_of_India.svg.webp" }) {
      ...fluidWebpImage
    }
    system: file(relativePath: { eq: "system.jpg" }) {
      ...fluidWebpImage
    }
  }
`
